import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useContext, useState, useEffect,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';

import { TvMain } from '@powdr/components';
import { ThemeProps as Prop, StaticProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import {
  componentFactory, sortNavigationByWeight, themeUtil,
} from '@powdr/utils';

import GlobalStyle from '../../utils/global-styles';

const SiteWrapper = styled.div`
  height: 100vh;
  background-color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};
`;

function TvLayout({
  data,
}) {
  const {
    defaultTheme,
  } = useContext(AppContext);
  const tvPages = {
    ...data.tvData,
    childPages: sortNavigationByWeight(
      data.tvData.relationships.pages.sort((a, b) => a.weight - b.weight),
    ),
  };
  const rotationData = (() => {
    const flatPages = [];
    const getFlatTvData = (childPages) => childPages.forEach((childPage) => {
      if (childPage.relationships?.pages?.length > 0) {
        if (childPage?.relationships?.tvComponents?.length > 0) {
          flatPages.push({
            name: (childPage?.displayTitle) ? childPage.displayTitle : childPage.name,
            link: childPage.link,
            displayTime: childPage.displayTime,
            components: [...childPage?.relationships?.tvComponents || []],
          });
        }
        getFlatTvData(childPage.relationships.pages.sort((a, b) => a.weight - b.weight));
      } else if (childPage?.relationships?.tvComponents?.length > 0) {
        flatPages.push({
          name: (childPage?.displayTitle) ? childPage.displayTitle : childPage.name,
          link: childPage.link,
          displayTime: childPage.displayTime,
          components: [...childPage?.relationships?.tvComponents || []],
        });
      }
    });
    getFlatTvData(tvPages.childPages);

    return flatPages;
  })();
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeData, setActiveData] = useState(rotationData[0]);
  const [pauseRotation, setPauseRotation] = useState(false);
  // const [activeComponentTimestamp, setActiveComponentTimestamp] = useState();
  const rotationTimeoutRef = useRef();
  // const animationSpeed = 1000; // ms

  const getNextSlideIndex = useCallback(
    () => ((activeIndex + 1 === rotationData.length) ? 0 : activeIndex + 1),
    [activeIndex, rotationData.length],
  );

  const handleNextItem = useCallback(() => {
    setActiveIndex(getNextSlideIndex());
    setActiveData(rotationData[getNextSlideIndex()]);
  }, [getNextSlideIndex, rotationData]);

  // TV rotation
  useEffect(() => {
    rotationTimeoutRef.current = setTimeout(() => {
      if (!pauseRotation) {
        handleNextItem();
      }
    }, rotationData[activeIndex].displayTime * 1000);

    return () => {
      clearTimeout(rotationTimeoutRef.current);
    };
  }, [activeIndex, handleNextItem, rotationData, pauseRotation]);

  useEffect(() => {
    if (pauseRotation) {
      console.log('TV rotation paused');
    } else {
      console.log('TV rotation active');
    }
  }, [pauseRotation]);

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      <SiteWrapper>
        <TvMain
          title={activeData.name}
          navPages={tvPages.childPages}
          // lastUpdatedTime="11:30 AM"
          activeItemData={activeData}
          setPauseState={setPauseRotation}
          pauseState={pauseRotation}
          nextItem={handleNextItem}
        >
          {componentFactory(
            activeData.components || [],
            undefined,
            undefined,
            {
              displayTime: activeData.displayTime,
            },
          )}
        </TvMain>
      </SiteWrapper>
    </>
  );
}

TvLayout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TvLayout;

export const query = graphql`
  query TvLayout($fullPath: String){
    site {
      ...SiteMetadataQuery
    }
    tvData: taxonomyTermTvNavigation( field_navigation_link: { eq: $fullPath } ) {
      ...TvNestedPagesQuery
    }
  }
`;
